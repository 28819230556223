import { navigate } from 'gatsby'
import React from 'react'
import Box from 'components/Box'
import ButtonHistoryBack from 'components/ButtonHistoryBack'
import Container from 'components/Container'
import Heading from 'components/Heading'
import Row from 'components/Row'
import * as events from 'constants/events'
import paths from 'constants/paths'
import { useGlobalContext } from 'contexts/GlobalContext'
import CheckinForm from 'features/checkin/components/CheckinForm'
import ProgramUpdateLayout from 'features/program/components/ProgramUpdateLayout'
import useEventPageVisited from 'hooks/use-event-page-visited'
import * as checkinApiRequest from 'libs/checkin-api-request'
import * as notifications from 'libs/notifications'
import CheckinTracker from 'libs/checkins/checkin-tracker'

export default function ProgramChange() {
  useEventPageVisited('Change program')
  const globalContext = useGlobalContext()
  const [checkinTracker, setCheckinTracker] = React.useState<CheckinTracker | null>(null)

  React.useEffect(() => {
    async function getCheckin() {
      const programChangeCheckinResponse = await checkinApiRequest
        .getUserCheckin({
          type: 'program_change',
          activeOnly: true,
          createIfNotExists: true,
        })
        .catch((error: Error) => {
          globalContext.analytics?.trackEvent(events.FAILED_CHECKIN_CALL, {
            error,
            from: 'program change',
            type: 'program_change',
          })
          notifications.notifyError()
        })

      const programChangeCheckin = programChangeCheckinResponse.data

      if (!programChangeCheckin) return

      setCheckinTracker(
        new CheckinTracker(globalContext.user?.id, programChangeCheckin, globalContext.analytics)
      )
    }
    getCheckin()
  }, [globalContext.user?.id, globalContext.analytics])

  function handleSuccess() {
    globalContext.analytics?.trackEvent('Program changed')
    navigate(`${paths.PROGRAM_BUILD}?changeRequested=true`, { replace: true })
  }

  return (
    <ProgramUpdateLayout title="Program change" className="ProgramChange">
      <Container flush size="medium">
        <Box color="white">
          <Row size="xmedium">
            <ButtonHistoryBack color="white" icon="arrow-left" />
          </Row>
          <Heading level={1} levelStyle={4}>
            Change my recommended program
          </Heading>
          <CheckinForm
            buttonProps={{
              children: 'Get a new program',
            }}
            // TODO
            buttonsProps={{
              align: 'right',
            }}
            checkinTracker={checkinTracker}
            size="small"
            onSuccess={handleSuccess}
          />
        </Box>
      </Container>
    </ProgramUpdateLayout>
  )
}
