import classNames from 'classnames'
import { StaticImage } from 'gatsby-plugin-image'
import _ from 'lodash'
import React from 'react'
import MarqueeFast from 'react-fast-marquee'
import {
  Accordion,
  AccordionItem,
  AccordionItemBody,
  AccordionItemTitle,
} from 'components/Accordion'
import Box from 'components/Box'
import Button from 'components/Button'
import Buttons from 'components/Buttons'
import Carousel from 'components/Carousel'
import Container from 'components/Container'
import Copy from 'components/Copy'
import Head from 'components/Head'
import Hero2 from 'components/Hero2'
import Image from 'components/Image'
import Layout from 'components/Layout'
import MediaObject from 'components/MediaObject'
import Module1s from 'components/Module1s'
import Row from 'components/Row'
import Text from 'components/Text'
import Title from 'components/Title'
import Video2 from 'components/Video2'
import View from 'components/View'
import paths from 'constants/paths'
import useEventPageVisited from 'hooks/use-event-page-visited'
import * as enterprise from 'libs/enterprise'
import styles from './Partnerships.module.scss'
import { ReactComponent as ExerciseSvg } from './images/programs/exercise.svg'
import { ReactComponent as FallSvg } from './images/programs/fall.svg'
import { ReactComponent as HouseMedicalCrossSvg } from './images/programs/house-medical-cross.svg'
import { ReactComponent as HandsMedicalCrossSvg } from './images/value-props/hands-medical-cross.svg'
import { ReactComponent as PiggyBankSvg } from './images/value-props/piggy-bank.svg'
import { ReactComponent as StarSvg } from './images/value-props/star.svg'

export default function Partnerships() {
  useEventPageVisited('Health plans')

  const product = [
    {
      title: 'Personalized',
      image: (
        <StaticImage
          src="./images/screenshots/dashboard.png"
          alt="Screenshot of the Bold dashboard"
          options={{
            quality: 100,
          }}
        />
      ),
      items: ['Dynamic adjustments', 'Ongoing assessments', 'Modular programming'],
    },
    {
      title: 'Engaging',
      image: (
        <StaticImage
          src="./images/screenshots/program.png"
          alt="Screenshot of a Bold program"
          options={{
            quality: 100,
          }}
        />
      ),
      items: [
        'Live and on-demand programming',
        'Customizable scheduling and reminders',
        'High member satisfaction leads to high member retention',
      ],
    },
    {
      title: 'Effective',
      image: (
        <StaticImage
          src="./images/screenshots/assessments.png"
          alt="Screenshot of Bold assessments"
          options={{
            quality: 100,
          }}
        />
      ),
      items: [
        '>2:1 clinical ROI in 1 year',
        '46% reduction in falls',
        '182% increase in weekly physical activity',
      ],
    },
  ]

  const [productActive, setProductActive] = React.useState(product[0].title)

  const valueProps = [
    {
      title: 'Drive value',
      copy: (
        <>
          Bold’s programs demonstrate a 46% reduction in falls, which impact more than 1 in 4
          Americans over 65 each year
          <br />
          <Text size="small">(Journal of Medical Internet Research; CDC)</Text>
        </>
      ),
      icon: PiggyBankSvg,
    },
    {
      title: 'Close care gaps',
      copy: (
        <>
          Exercise mitigates all top 10 chronic conditions impacting older adults
          <br />
          <Text size="small">(CDC)</Text>
        </>
      ),
      icon: HandsMedicalCrossSvg,
    },
    {
      title: 'Improve star ratings',
      copy: (
        <>
          Members give Bold high marks, with a 91% satisfaction rating
          <br />
          <Text size="small">(Bold book of business, 2022)</Text>
        </>
      ),
      icon: StarSvg,
    },
  ]

  const programs = [
    {
      title: 'Fall prevention',
      copy: 'Keeping older adults active and safe',
      icon: FallSvg,
    },
    {
      title: 'Pain management',
      copy: 'Reduce hospitalizations and surgeries and promote lifelong well-being',
      icon: HouseMedicalCrossSvg,
    },
    {
      title: 'Functional fitness',
      copy: 'Improve physical activity and support sustainable, healthy habits',
      icon: ExerciseSvg,
    },
  ]

  const testimonials = [
    'https://vimeo.com/602995702',
    'https://vimeo.com/602984115',
    'https://vimeo.com/602982700',
  ]

  const productActiveItem = product.find((item) => item.title === productActive)

  const buttonProps = {
    size: 'large',
  }

  return (
    <Layout className={classNames('Partnerships', styles.this)} data-test-id="partnerships">
      <Head
        title="Health plans and Bold"
        description="Our health plan partnerships save money and improve lives. Bold’s programs achieve clinical outcomes most relevant to Medicare plans."
      />
      <Row size="xxxlarge">
        <Hero2
          title="Reduce falls, increase activity, improve satisfaction"
          subtext="Our digital platform provides personalized, evidence-based programming that drives clinical outcomes and delights members. Our programs have demonstrated a 46% reduction in falls, and nearly 8 in 10 members would be more likely to choose a Medicare plan that offers Bold."
          button={
            <Button to={paths.REQUEST_DEMO} color="yellow" size="xlarge">
              Request demo
            </Button>
          }
          image={
            <StaticImage
              src="./images/header.jpg"
              alt=""
              layout="fullWidth"
              options={{
                quality: 100,
              }}
            />
          }
          bottom={
            <>
              <Row size="large">
                <MarqueeFast gradient={false} speed={10} className={styles.partners}>
                  {_.times(2, () =>
                    Object.keys(enterprise.PARTNERS)
                      .filter(
                        (item) =>
                          ![
                            enterprise.DEMO_CLINICAL_KEY,
                            enterprise.GROUPER_KEY,
                            enterprise.OTTER_KEY,
                            enterprise.PATINA_KEY,
                            enterprise.SEAL_KEY,
                          ].includes(item)
                      )
                      .map((item) => (
                        <Image
                          src={enterprise.getLogo(item)}
                          alt={enterprise.getName(item)}
                          key={item}
                          className={styles['partners--logo']}
                        />
                      ))
                  )}
                </MarqueeFast>
              </Row>
              <Container size="xxlarge">
                <Row size="large">
                  <Container flush size="large">
                    <Box align="center" color="white" size="large">
                      <Container flush>
                        <Title
                          subtext="Bold’s dynamic exercise programs support members of all activity levels and reduce meaningful health spend and hospitalizations."
                          flush>
                          A preventive, engaging approach for your diverse membership
                        </Title>
                      </Container>
                    </Box>
                  </Container>
                </Row>
                <Module1s data={valueProps} />
              </Container>
            </>
          }
        />
      </Row>
      <View flush className={styles.view}>
        <Row size="xxxlarge">
          <Container size="xxlarge">
            <Row size="xxxlarge">
              <Container flush>
                <Title subtext="Bold’s engaging programs are designed to achieve clinical outcomes and advance member satisfaction metrics most relevant to Medicare plans.">
                  Our programs save money, reduce churn, and improve lives
                </Title>
              </Container>
              <Row>
                <Module1s data={programs} />
              </Row>
              <Buttons align="center">
                <Button {...buttonProps} to={paths.REQUEST_DEMO}>
                  Contact us to learn more
                </Button>
              </Buttons>
            </Row>

            <Row size="xxxlarge">
              <Container flush>
                <Title subtext="Bold’s platform leverages cutting-edge behavioral science to engage members and promote durable habits, while providing an affirming, accessible experience that delivers leading outcomes and cost savings.">
                  The only purpose-built healthy aging platform for Medicare
                </Title>
              </Container>
              <MediaObject
                figure={
                  <Accordion
                    preExpanded={[`${product[0].title}`]}
                    className={styles['product-accordion']}
                    onChange={(activeUuids) => setProductActive(activeUuids[0])}>
                    {product.map((item) => (
                      <AccordionItem key={item.title} uuid={item.title}>
                        <AccordionItemTitle>
                          <Text element="h3" size="large" weight="bold">
                            {item.title}
                          </Text>
                        </AccordionItemTitle>
                        <AccordionItemBody>
                          <Copy>
                            <Text element="ul">
                              {item.items.map((itemCopy) => (
                                <Text element="li">{itemCopy}</Text>
                              ))}
                            </Text>
                          </Copy>
                        </AccordionItemBody>
                      </AccordionItem>
                    ))}
                  </Accordion>
                }
                center
                figurePosition="right"
                size="xlarge"
                className={styles.product}>
                <Text align="center" element="figure">
                  <Container flush size="large">
                    <Box color="grey">
                      {React.cloneElement(productActiveItem.image, {
                        className: styles['product-accordion--screenshot'],
                      })}
                    </Box>
                  </Container>
                </Text>
              </MediaObject>
            </Row>
          </Container>
        </Row>
        <Container size="xxlarge">
          <Container flush>
            <Title subtext="We partner with Medicare Advantage plans, dual eligible plans, and healthcare providers. Your members could be saying:">
              Bring Bold to your organization
            </Title>
          </Container>
        </Container>
        <Row>
          <Carousel
            pagination={{
              clickable: true,
            }}>
            {testimonials.map((item) => (
              <div key={item}>
                <Container size="large">
                  <Video2 url={item} rounded />
                </Container>
              </div>
            ))}
          </Carousel>
        </Row>
        <Buttons align="center">
          <Button {...buttonProps} to={paths.REQUEST_DEMO}>
            Contact us to learn more
          </Button>
        </Buttons>
      </View>
    </Layout>
  )
}
