import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import Buttons from 'components/Buttons'
import Heading from 'components/Heading'
import Row from 'components/Row'
import type { Component } from 'constants/types'
import styles from './ExploreSection.module.scss'

export type Props = {
  action: React.ReactElement
  children: React.ReactElement[] | React.ReactElement
  title: string
} & Component

export default function ExploreSection({ action, children, className, title }: Props) {
  return (
    <section className={classNames('ExploreSection', className)}>
      {_.isString(title) ? (
        <Heading level={2} levelStyle={4}>
          {title}
        </Heading>
      ) : (
        <Row size="xsmall">{title}</Row>
      )}
      <Row size="small">
        <div className={styles['grid-wrap']}>
          <div className={styles.grid}>{children}</div>
        </div>
      </Row>
      {!!action && <Buttons align="center">{action}</Buttons>}
    </section>
  )
}
