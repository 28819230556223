import classNames from 'classnames'
import React from 'react'
import Container from 'components/Container'
import type { Component } from 'constants/types'
import styles from './HeaderSubheader.module.scss'

type Props = {
  center?: boolean
  children: React.ReactNode
  shadow?: boolean
} & Component

export default function HeaderSubheader({ center, children, className, shadow = true }: Props) {
  return (
    <header
      className={classNames(
        'HeaderSubheader',
        styles.this,
        shadow && styles['this---shadow'],
        className
      )}>
      <Container size="xxlarge" className={center && styles.container}>
        {children}
      </Container>
    </header>
  )
}
