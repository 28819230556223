// TODO
import { graphql } from 'gatsby'
import _ from 'lodash'
import React from 'react'
import Container from 'components/Container'
import Head from 'components/Head'
import Heading from 'components/Heading'
import Image from 'components/Image'
import Layout from 'components/Layout'
import Lessons from 'components/Lessons'
import Link from 'components/Link'
import Markdown from 'components/Markdown'
import Row from 'components/Row'
import Text from 'components/Text'
import View from 'components/View'
import { useGlobalContext } from 'contexts/GlobalContext'
import LiveLessons from 'features/live-lessons/pages/LiveLessons/components/LiveLessons'
import useEventPageVisited from 'hooks/use-event-page-visited'
import styles from './Trainer.module.scss'

type Props = {
  data: {
    mdx: any
    trainers: any
  }
}

export default function Trainer({ data }: Props) {
  useEventPageVisited('Trainer', { name: data?.mdx?.frontmatter?.name })
  const globalContext = useGlobalContext()
  const [hasLessons, setHasLessons] = React.useState(false)
  const [hasLiveLessons, setHasLiveLessons] = React.useState(false)

  React.useEffect(() => {
    if (globalContext?.user?.id) {
      setHasLessons(true)
      setHasLiveLessons(true)
    }
  }, [globalContext?.user])

  if (!data) return null

  const { mdx, trainers } = data
  const { body, frontmatter } = mdx
  const { certifications, image16x9, name } = frontmatter

  const hasCertifications = !_.isEmpty(certifications)
  const trainersGroup = _.sampleSize(trainers.nodes, 3)

  return (
    <Layout className="Trainer">
      <Head title={name} image={image16x9.publicURL} />
      <header className={styles.header}>
        <div className={styles['header--body']}>
          <Container size="medium">
            <Heading level={1} levelStyle={4}>
              {name}
            </Heading>
            <Row flush={!hasCertifications}>
              <Markdown>{body}</Markdown>
            </Row>
            {hasCertifications && (
              <>
                <Heading level={2} levelStyle={6}>
                  Certifications
                </Heading>
                <Text copy element="ul">
                  {certifications.map((item: string) => (
                    <li key={item}>{item}</li>
                  ))}
                </Text>
              </>
            )}
          </Container>
        </div>
        <Image src={image16x9} className={styles['header--image']} />
      </header>
      <View color="grey">
        <Container flush size="xxlarge">
          {hasLiveLessons && (
            <Row size="large">
              <Heading level={2} levelStyle={4}>
                Live schedule
              </Heading>
              <LiveLessons trainer={name} onEmpty={() => setHasLiveLessons(false)} />
            </Row>
          )}
          {hasLessons && (
            <Row size="large">
              <Heading level={2} levelStyle={4}>
                On-demand classes
              </Heading>
              <Lessons trainer={name} onEmpty={() => setHasLessons(false)} />
            </Row>
          )}
          <Row size="large">
            <Heading level={2} levelStyle={4}>
              Other trainers you may enjoy
            </Heading>
            <div className={styles.trainers}>
              {trainersGroup.map((item) => (
                <Link to={item.fields.slug} key={item.id} className={styles['trainers--item']}>
                  <Text
                    color="white"
                    element="h3"
                    size="large"
                    weight="bold"
                    className={styles['trainers--item--name']}>
                    {item.frontmatter.name}
                  </Text>
                  <Image
                    //src={item.frontmatter.imageSilhouette}
                    src={item.frontmatter.imageSilhouette.publicURL}
                    className={styles['trainers--item--image']}
                  />
                </Link>
              ))}
            </div>
          </Row>
        </Container>
      </View>
    </Layout>
  )
}

export const pageQuery = graphql`
  query TrainerQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        certifications
        image16x9 {
          childImageSharp {
            fluid(maxWidth: 768) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          publicURL
        }
        name
      }
    }
    trainers: allMdx(
      filter: { frontmatter: { template: { eq: "trainer" } }, id: { ne: $id } } #limit: 3
    ) {
      nodes {
        id
        fields {
          slug
        }
        frontmatter {
          imageSilhouette {
            #childImageSharp {
            #  fluid(maxHeight: 220) {
            #  #fixed(
            #  #  height: 220,
            #  #  fit: CONTAIN
            #  #) {
            #    #...GatsbyImageSharpFixed_withWebp
            #    ...GatsbyImageSharpFluid_withWebp
            #  }
            #}
            publicURL
          }
          name
        }
      }
    }
  }
`
