import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import Box from 'components/Box'
import BoxHeader from 'components/BoxHeader'
import Button from 'components/Button'
import Carousel from 'components/Carousel'
import Heading from 'components/Heading'
import Image from 'components/Image'
import Loader from 'components/Loader'
import MediaObject from 'components/MediaObject'
import Row from 'components/Row'
import Tag from 'components/Tag'
import Tags from 'components/Tags'
import Text from 'components/Text'
import TextWithIcon from 'components/TextWithIcon'
import { useGlobalContext } from 'contexts/GlobalContext'
import type { Lesson } from 'libs/lesson'
import * as lessonLib from 'libs/lesson'
import type { ProgramDynamicBlock } from 'libs/program'
import { useUserBlockLessons } from 'libs/program-query'
import styles from './ProgramBlock.module.scss'
import { ReactComponent as CheckmarkCircleSvg } from './images/checkmark-circle.svg'
import { ReactComponent as ChevronDownSvg } from './images/chevron---down.svg'
import { ReactComponent as ChevronUpSvg } from './images/chevron---up.svg'

export type Props = {
  data: ProgramDynamicBlock
  lessonOverride: any
  // eslint-disable-next-line no-unused-vars
  onLessonClick: (lesson: any) => void
  onLessonSkipped: any
  playSkippedLesson: any
  postLessonState: Boolean
  currentBlock: any
}

export default function ProgramBlock({
  data,
  lessonOverride,
  onLessonClick,
  onLessonSkipped,
  playSkippedLesson,
  postLessonState,
  currentBlock,
}: Props) {
  const globalContext = useGlobalContext()

  const { data: userBlockLessonsResponse, mutate: mutateUserBlockLessons } = useUserBlockLessons(
    data?.id
  )
  const blockLessons = userBlockLessonsResponse?.data
  const [showLessons, setShowLessons] = React.useState()
  const [currentLesson, setCurrentLesson] = React.useState(null)

  const isActiveBlock = data?.status === 'active' // TODO: use constants
  const isNextBlock =
    data && currentBlock ? data.indexInProgram === currentBlock.indexInProgram + 1 : false
  const hasStartedProgram = !_.isEmpty(_.filter(blockLessons, (lesson) => lesson?.userView != null))
  const numberClasses = blockLessons?.length
  const numberClassesCompletedOrSkipped = _.filter(blockLessons, (lesson) =>
    _.includes(['completed', 'skipped'], lesson?.userView?.status)
  ).length

  React.useEffect(() => {
    if (showLessons && currentBlock?.id === data.id && !_.isEmpty(blockLessons)) {
      for (const lesson of blockLessons) {
        if (!lesson?.userView || !_.includes(['completed', 'skipped'], lesson?.userView?.status)) {
          setCurrentLesson(lesson)
          break
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBlock, blockLessons, showLessons])

  React.useEffect(() => {
    mutateUserBlockLessons()
  }, [data, mutateUserBlockLessons])

  React.useEffect(() => {
    if (onLessonSkipped || playSkippedLesson) {
      mutateUserBlockLessons()
    }
  }, [onLessonSkipped, mutateUserBlockLessons, playSkippedLesson])

  const handleToggleLessonList = async () => {
    globalContext.analytics?.trackEvent(
      `Program: Block: ${showLessons ? 'Hide' : 'View'} Classes Button: Click`
    )

    setShowLessons(!showLessons)
  }

  React.useEffect(() => {
    setShowLessons(isActiveBlock)
  }, [isActiveBlock])

  function handleLessonClick(lesson: Lesson) {
    globalContext.analytics?.trackEvent('Program: Block: Lesson: Click')
    setCurrentLesson(lesson)
    onLessonClick(lesson)

    // mutateBlockLessons()
    // mutateProgram()
  }

  let headerRight: React.ReactNode =
    !_.isNil(numberClassesCompletedOrSkipped) && !_.isNil(numberClasses)
      ? isActiveBlock
        ? `${numberClassesCompletedOrSkipped}/${numberClasses} completed`
        : `${numberClasses} classes`
      : ''

  if (numberClassesCompletedOrSkipped >= numberClasses) {
    headerRight = <TextWithIcon icon={<CheckmarkCircleSvg />}>Completed</TextWithIcon>
  }

  if (_.isNil(data)) return null

  let description = null
  if (numberClasses) {
    description =
      data.indexInProgram === 0
        ? `Get started with your first ${numberClasses} Bold classes.`
        : `Build on your progress with your next ${numberClasses} classes.`
  }

  const tagProps = {
    casing: 'normal',
    size: 'medium',
  }

  return (
    <Box
      color="white"
      header={
        <BoxHeader>
          <MediaObject
            figure={<Text weight="bold">{headerRight}</Text>}
            center
            figurePosition="right"
            size="small">
            <div className={styles.tags}>
              <Tag {...tagProps} color="purple">
                Section {data.indexInProgram + 1}
              </Tag>
              {isActiveBlock && (
                <Tag {...tagProps} color="green">
                  {hasStartedProgram ? 'In progress' : 'Current'}
                </Tag>
              )}
              {isNextBlock && (
                <Tag {...tagProps} color="pink">
                  Up next
                </Tag>
              )}
            </div>
          </MediaObject>
        </BoxHeader>
      }
      className={classNames('ProgramBlock', styles.this)}>
      <Heading level={2} levelStyle={5}>
        Week {data.indexInProgram + 1}
      </Heading>
      <Text element="p">{description}</Text>
      <Row size="xxsmall">
        <Button
          icon={showLessons ? <ChevronUpSvg /> : <ChevronDownSvg />}
          iconPosition="right"
          level="text"
          className={styles.button}
          onClick={handleToggleLessonList}>
          {showLessons ? 'Hide' : 'View'} classes
        </Button>
      </Row>
      {showLessons && (
        <div className={styles.lessons}>
          {_.isEmpty(blockLessons) ? (
            <Loader overlay={false} />
          ) : (
            <Carousel
              initialSlide={_.findIndex(
                blockLessons,
                (item) => item.userView?.status !== 'completed'
              )}
              loop={false}
              navigationProps={{
                background: true,
              }}
              showNavigation
              slidesOffsetBefore={12}
              slidesOffsetAfter={12}
              slidesPerView={2.15}>
              {blockLessons.map((lesson: Lesson) => {
                const classTypes = lesson.classType?.filter(Boolean)
                const image = lesson.videoThumbnail
                const isCompleted = lesson.userView?.status === 'completed'
                const isSkipped = lesson.userView?.status === 'skipped'
                const isCurrent =
                  !postLessonState &&
                  !isCompleted &&
                  !isSkipped &&
                  ((isActiveBlock && currentLesson?.id === lesson?.id) ||
                    lesson.id === lessonOverride?.id)

                return (
                  <div
                    key={lesson.id}
                    className={classNames(
                      styles.lesson,
                      isCurrent && styles['lesson---current'],
                      isCompleted && styles['lesson---completed']
                    )}
                    onClick={() => handleLessonClick(lesson)}>
                    <Row size="xsmall">
                      <div className={styles['lesson--image-wrap']}>
                        <Image src={image} full className={styles['lesson--image']} />
                        <div className={styles['lesson--image-overlay']}>
                          <Tags data={[lessonLib.getStatus(lesson)]} color="black" />
                        </div>
                      </div>
                    </Row>
                    <Row size="xxxsmall">
                      <Text element="h3" weight="bold">
                        {lesson.videoTitle}
                      </Text>
                    </Row>
                    <Text size="small">
                      {lesson.instructorName}
                      {!_.isEmpty(classTypes) && (
                        <>
                          {' • '}
                          {lessonLib.rewriteClassTypes(classTypes)}
                        </>
                      )}
                    </Text>
                  </div>
                )
              })}
            </Carousel>
          )}
        </div>
      )}
    </Box>
  )
}
