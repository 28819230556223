import classNames from 'classnames'
import React from 'react'
import Row from 'components/Row'
import Text from 'components/Text'
import type { Component } from 'constants/types'
import styles from './BlogTitle.module.scss'

type Props = {
  children: React.ReactNode
  flush?: boolean
  small: string
} & Component

export default function BlogTitle({ children, className, flush, small }: Props) {
  return (
    <Text
      casing="upper"
      color="purple"
      element="h2"
      weight="bold"
      className={classNames('BlogTitle', styles.this, flush && styles['this---flush'], className)}>
      <Row size="xxsmall">
        <Text color="default" size="large" className={styles.small}>
          {small}
        </Text>
      </Row>
      {children}
    </Text>
  )
}
