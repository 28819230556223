import { graphql } from 'gatsby'
import React from 'react'
import Container from 'components/Container'
import Head from 'components/Head'
import Image from 'components/Image'
import Layout from 'components/Layout'
import Link from 'components/Link'
import Row from 'components/Row'
import Text from 'components/Text'
import Title from 'components/Title'
import View from 'components/View'
import useEventPageVisited from 'hooks/use-event-page-visited'
import styles from './Trainers.module.scss'

type Props = {
  data: {
    allMdx: any
  }
}

export default function Trainers({ data }: Props) {
  const { edges: trainers } = data.allMdx

  useEventPageVisited('Trainers')

  const title = 'Bold Trainers'
  const description =
    'Meet Bold’s team of experts on exercise and the science of healthy aging. Bold instructors specialize in a wide range of class styles, such as balance, mobility, strength training, yoga, meditation, cardio, dance, Tai Chi, pilates and more. Learn more about the amazing individuals who create and teach Bold’s classes.'

  return (
    <Layout className="Trainers">
      <Head title={title} description={description} titleOnly />
      <View>
        <Container size="xlarge">
          <Container flush>
            <Title subtext={description} size="large">
              {title}
            </Title>
          </Container>
          <div className={styles.grid}>
            {trainers.map((item) => {
              const { node: trainer } = item

              return (
                <Link to={trainer.fields.slug} key={trainer.fields.slug}>
                  <Text align="center" element="figure" className={styles.trainer}>
                    <Row size="xsmall">
                      <Image
                        src={trainer.frontmatter.image16x9}
                        className={styles['trainer--image']}
                      />
                    </Row>
                    <Row size="xsmall">
                      <Text element="h3" size="xlarge" weight="bold">
                        {trainer.frontmatter.name}
                      </Text>
                    </Row>
                    <Text
                      casing="upper"
                      color="purple"
                      weight="bold"
                      className={styles['trainer--button']}>
                      View profile
                    </Text>
                  </Text>
                </Link>
              )
            })}
          </div>
        </Container>
      </View>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allMdx(
      filter: { frontmatter: { template: { eq: "trainer" } } }
      sort: { fields: [frontmatter___name] }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            image16x9 {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            name
          }
        }
      }
    }
  }
`
