import React from 'react'
import Box from 'components/Box'
import Container from 'components/Container'
import Head from 'components/Head'
import Heading from 'components/Heading'
import Text from 'components/Text'
import useEventPageVisited from 'hooks/use-event-page-visited'
import Page2 from 'templates/Page2'

export default function Responses30Days() {
  useEventPageVisited('Response - 30 days')

  const title = 'Thank you!'

  return (
    <Page2 className="Responses30Days">
      <Head title={title} />
      <Container flush size="xmedium">
        <Box align="center">
          <Heading level={1} levelStyle={4}>
            {title}
          </Heading>
          <Text element="p" flush>
            Thank you for letting us know your preferences, we will reach out in 30 days.
          </Text>
        </Box>
      </Container>
    </Page2>
  )
}
