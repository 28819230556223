import { datadogLogs } from '@datadog/browser-logs'
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react'
import { Router, useLocation } from '@reach/router'
import { SkipNavContent, SkipNavLink } from '@reach/skip-nav'
import classNames from 'classnames'
import { navigate } from 'gatsby'
import _ from 'lodash'
import React from 'react'
import * as rdd from 'react-device-detect'
import { StripeProvider } from 'react-stripe-elements'
import ErrorBoundary from 'components/ErrorBoundary'
import Footer from 'components/Footer'
import Header from 'components/Header'
import { Route, RoutePrivate } from 'components/Route'
import experiments from 'constants/experiments'
import paths from 'constants/paths'
import { useGlobalContext } from 'contexts/GlobalContext'
import AdminCollectionCreate from 'features/admin/pages/AdminCollectionCreate'
import AdminEnterprise from 'features/admin/pages/AdminEnterprise'
import AdminEnterpriseMemberProfile from 'features/admin/pages/AdminEnterpriseMemberProfile'
import AdminEnterpriseMemberSearch from 'features/admin/pages/AdminEnterpriseMemberSearch'
import AdminLanding from 'features/admin/pages/AdminLanding'
import AdminLessonRegimens from 'features/admin/pages/AdminLessonRegimens'
import AdminLessons from 'features/admin/pages/AdminLessons'
import AdminLessonStreams from 'features/admin/pages/AdminLessonStreams'
import AdminUsers from 'features/admin/pages/AdminUsers'
import TrainerDashboard from 'features/admin/pages/TrainerDashboard'
import PelvicAssessment from 'features/assessments/components/PelvicAssessment'
import Assessments from 'features/assessments/pages/Assessments'
import StandaloneCheckin from 'features/checkin/pages/StandaloneCheckin'
import EnterpriseEligible from 'features/enterprise/components/EnterpriseEligible'
import EnterpriseReefOrcaCheckinModal from 'features/enterprise/reef-orca/components/EnterpriseReefOrcaCheckinModal'
import Explore from 'features/explore/pages/Explore'
import ExploreBoldTalks from 'features/explore/pages/ExploreBoldTalks'
import ExploreLessons from 'features/explore/pages/ExploreLessons'
import ExplorePlaylists from 'features/explore/pages/ExplorePlaylists'
import Lesson from 'features/lesson/pages/Lesson'
import LiveLessonFeedback from 'features/live-lesson/pages/LiveLessonFeedback'
import LiveRestreamLesson from 'features/live-lesson/pages/LiveRestreamLesson'
import LiveLessons from 'features/live-lessons/pages/LiveLessons'
import OnboardingAssessmentResults from 'features/onboarding/pages/OnboardingAssessmentResults'
import OnboardingEnterpriseEligibility from 'features/onboarding/pages/OnboardingEnterpriseEligibility'
import OnboardingEnterpriseEligibilityRecheck from 'features/onboarding/pages/OnboardingEnterpriseEligibilityRecheck'
import OnboardingEnterpriseReefCheckLoader from 'features/onboarding/pages/OnboardingEnterpriseReefCheckLoader'
import OnboardingIntro from 'features/onboarding/pages/OnboardingIntro'
import OnboardingPayment from 'features/onboarding/pages/OnboardingPayment'
import OnboardingPhoneNumber from 'features/onboarding/pages/OnboardingPhoneNumber'
import OnboardingProfile from 'features/onboarding/pages/OnboardingProfile'
import OnboardingProgramBuild from 'features/onboarding/pages/OnboardingProgramBuild'
import OnboardingProgramIntroduction from 'features/onboarding/pages/OnboardingProgramIntroduction'
import OnboardingQuestions from 'features/onboarding/pages/OnboardingQuestions'
import Playlist from 'features/playlists/pages/Playlist'
import Program from 'features/program/components/Program'
import ProgramBuild from 'features/program/pages/ProgramBuild'
import ProgramChange from 'features/program/pages/ProgramChange'
import NpsModal from 'features/user/components/NpsModal'
import SubscriptionTrialExpirationBanner from 'features/user/components/SubscriptionTrialExpirationBanner'
import Activity from 'features/user/pages/Activity'
import Badges from 'features/user/pages/Badges'
import Landing from 'features/user/pages/Landing'
import LessonsFavorite from 'features/user/pages/LessonsFavorite'
import Movement from 'features/user/pages/Movement'
import Movements from 'features/user/pages/Movements'
import Referrals from 'features/user/pages/Referrals'
import Settings from 'features/user/pages/Settings'
import SettingsInvoices from 'features/user/pages/SettingsInvoices'
import SettingsSubscription from 'features/user/pages/SettingsSubscription'
import SettingsSubscriptionChange from 'features/user/pages/SettingsSubscriptionChange'
import SettingsSubscriptionChangeSuccess from 'features/user/pages/SettingsSubscriptionChangeSuccess'
import Unsubscribe from 'features/user/pages/Unsubscribe'
import * as environment from 'libs/environment'
import * as segment from 'libs/segment'
import * as user from 'libs/user'
import '@reach/skip-nav/styles.css'
import styles from './App.module.scss'

const growthbook = new GrowthBook({
  ...experiments.GROWTHBOOK_SETTINGS,
  trackingCallback: (experiment, result) => {
    segment.track('Experiment Viewed', {
      'Experiment Id': experiment.key,
      'Variant Id': result.variationId,
      'Variation Value': result.value,
      '$source': 'growthbook',
    })
  },
})
growthbook.init({ timeout: 2000 })

export default function App() {
  const globalContext = useGlobalContext()
  const location = useLocation()
  const [stripe, setStripe] = React.useState(null)

  React.useEffect(() => {
    async function checkOBStatus() {
      if (!globalContext.user) return

      if (
        !globalContext.user.isOnboardingCompleted &&
        !paths.ONBOARDING_PATHS.includes(location.pathname)
      ) {
        navigate(paths.ONBOARDING_QUESTIONS)
      }
    }
    checkOBStatus()
  }, [globalContext.analytics, globalContext.user, location.pathname])

  React.useEffect(() => {
    if (globalContext.isEnterprise) return

    const stripeScript = document.createElement('script')
    stripeScript.setAttribute('id', 'stripe-js')

    document.body.append(stripeScript)

    stripeScript.addEventListener('load', () => {
      // TODO: Put into AWS Secrets Manager
      // Create Stripe instance once Stripe.js loads
      setStripe(
        window.Stripe(
          environment.isProduction
            ? 'pk_live_dqOHAXJqGuoKlqhhWdwzTC1w0097TiyBdl'
            : 'pk_test_d9rIuMZxCsv0bft7yHF7y3rX00Bc1V22Tk'
        )
      )
    })

    stripeScript.src = 'https://js.stripe.com/v3/'
  }, [globalContext.isEnterprise])

  const userId = globalContext?.user?.id

  React.useEffect(() => {
    if (_.isNil(userId)) return

    datadogLogs.setGlobalContextProperty('userId', userId)

    growthbook.setAttributes({
      ...growthbook.getAttributes(), // preserve existing attributes
      browser: rdd.browserName,
      deviceType: rdd.deviceType,
      fullBrowserVersion: rdd.fullBrowserVersion,
      id: userId, // || window.analytics?.user().anonymousId(),
      isProductionEnv: environment.isProduction,
      os: rdd.osName,
      url: window.location.href,
    })
  }, [userId])

  const isAdminUser = user.isAdminUser(globalContext.user)
  const isTrainerUser = user.isTrainerUser(globalContext.user)

  return (
    <ErrorBoundary>
      <GrowthBookProvider growthbook={growthbook}>
        <StripeProvider stripe={stripe}>
          <div className={classNames('App', styles.this)}>
            {/* not wrapping caused really odd behavior at /app/live/ on refresh _only_ on build */}
            <div>
              <SkipNavLink />
              <Header
                banner={<SubscriptionTrialExpirationBanner />}
                hideForPaths={[
                  paths.ACTIVITY,
                  paths.EXPLORE_LESSONS,
                  paths.LIVE_LESSONS,
                  paths.PELVIC_ASSESSMENT,
                  paths.SETTINGS,
                ]}
              />
            </div>
            <SkipNavContent />
            {globalContext.user?.isOnboardingCompleted && <NpsModal />}
            {globalContext.user?.isOnboardingCompleted && <EnterpriseReefOrcaCheckinModal />}
            <div className={styles.main}>
              {/* prettier-ignore */}
              <Router className={styles.router}>
                <RoutePrivate path={paths.HOME_AUTHED} component={Landing} />
                <RoutePrivate path={paths.ACTIVITY} component={Activity} />
                <RoutePrivate path={paths.ASSESSMENTS_AUTHED} component={Assessments} />
                <RoutePrivate path={paths.LIVE_LESSON_FEEDBACK} component={LiveLessonFeedback} />
                <RoutePrivate path={paths.BADGES} component={Badges} />
                <RoutePrivate path={paths.ENTERPRISE_ELIGIBLE} component={EnterpriseEligible} />
                <RoutePrivate path={paths.EXPLORE} component={Explore} />
                <RoutePrivate path={paths.EXPLORE_BOLD_TALKS} component={ExploreBoldTalks} />
                <RoutePrivate path={paths.EXPLORE_LESSONS} component={ExploreLessons} />
                <RoutePrivate path={`${paths.LESSON}:lessonId`} component={Lesson} />
                <RoutePrivate path={paths.LESSONS_FAVORITE} component={LessonsFavorite} />
                <RoutePrivate path={paths.LIVE_LESSONS} component={LiveLessons} />
                <RoutePrivate path={`${paths.LIVE_LESSONS}:lessonId`} component={LiveLessons} />
                <RoutePrivate path={`${paths.LIVE_RESTREAM_LESSON}:lessonStreamId`} component={LiveRestreamLesson} />
                <RoutePrivate path={paths.MOVEMENTS} component={Movements} />
                <RoutePrivate path={`${paths.MOVEMENTS}:movementId`} component={Movement} />
                {/* TODO: rm when api/emails updated */}
                <RoutePrivate path={`${paths.LIVE_LESSONS}sign-up/:lessonId`} component={LiveLessons} />
                <RoutePrivate path={paths.ONBOARDING_ASSESSMENT_RESULTS} component={OnboardingAssessmentResults} />
                <RoutePrivate path={paths.ONBOARDING_ENTERPRISE_ELIGIBILITY} component={OnboardingEnterpriseEligibility} />
                <RoutePrivate path={paths.ONBOARDING_ENTERPRISE_ELIGIBILITY_RECHECK} component={OnboardingEnterpriseEligibilityRecheck} />
                <RoutePrivate path={paths.ONBOARDING_ENTERPRISE_REEF_CHECK_LOADER} component={OnboardingEnterpriseReefCheckLoader} />
                <RoutePrivate path={paths.ONBOARDING_INTRO} component={OnboardingIntro} />
                <RoutePrivate path={paths.ONBOARDING_PAYMENT} component={OnboardingPayment} />
                <RoutePrivate path={paths.ONBOARDING_PHONE_NUMBER} component={OnboardingPhoneNumber} />
                <RoutePrivate path={paths.ONBOARDING_PROFILE} component={OnboardingProfile} />
                <RoutePrivate path={paths.ONBOARDING_QUESTIONS} component={OnboardingQuestions} />
                <RoutePrivate path={paths.ONBOARDING_PROGRAM_BUILD} component={OnboardingProgramBuild} />
                <RoutePrivate path={paths.ONBOARDING_PROGRAM_INTRODUCTION} component={OnboardingProgramIntroduction} />
                <RoutePrivate path={paths.PELVIC_ASSESSMENT} component={PelvicAssessment} />
                <RoutePrivate path={paths.PLAYLISTS} component={ExplorePlaylists} />
                <RoutePrivate path={`${paths.PLAYLIST}:playlistId`} component={Playlist} />
                <RoutePrivate path={paths.PROGRAM} component={Program} />
                <RoutePrivate path={paths.PROGRAM_BUILD} component={ProgramBuild} />
                <RoutePrivate path={paths.PROGRAM_CHANGE} component={ProgramChange} />
                <RoutePrivate path={paths.JUST_CHECKING_IN} component={StandaloneCheckin} />
                <RoutePrivate path={paths.REFERRALS} component={Referrals} />
                <RoutePrivate path={paths.SETTINGS} component={Settings} />
                <RoutePrivate path={paths.SETTINGS_INVOICES} component={SettingsInvoices} />
                <RoutePrivate path={paths.SETTINGS_SUBSCRIPTION} component={SettingsSubscription} />
                <RoutePrivate path={paths.SETTINGS_SUBSCRIPTION_CHANGE} component={SettingsSubscriptionChange} />
                <RoutePrivate path={paths.SETTINGS_SUBSCRIPTION_CHANGE_SUCCESS} component={SettingsSubscriptionChangeSuccess} />

                <Route path={`${paths.UNSUBSCRIBE}:unsubscribeId`} component={Unsubscribe} />

                {isAdminUser && <Route path={paths.ADMIN} component={AdminLanding} />}
                {isAdminUser && <Route path={paths.ADMIN_ENTERPRISE_MEMBER_SEARCH} component={AdminEnterpriseMemberSearch} />}
                {isAdminUser && <Route path={paths.ADMIN_ENTERPRISE_MEMBER_PROFILE} component={AdminEnterpriseMemberProfile} />}
                {isAdminUser && <Route path={paths.ADMIN_COLLECTION_CREATE} component={AdminCollectionCreate} />}
                {isAdminUser && <Route path={paths.ADMIN_ENTERPRISE} component={AdminEnterprise} />}
                {isAdminUser && <Route path={paths.ADMIN_LESSON_REGIMENS} component={AdminLessonRegimens} />}
                {isAdminUser && <Route path={paths.ADMIN_LESSON_STREAMS} component={AdminLessonStreams} />}
                {isAdminUser && <Route path={paths.ADMIN_LESSONS} component={AdminLessons} />}
                {isAdminUser && <Route path={paths.ADMIN_USERS} component={AdminUsers} />}
                {isAdminUser && <Route path={`${paths.ADMIN_USERS}:id`} component={AdminUsers} />}

                {isTrainerUser && <Route path={paths.TRAINER} component={TrainerDashboard} />}
              </Router>
            </div>
            {!(isAdminUser || isTrainerUser) && (
              <Footer
                hideForPaths={[paths.PROGRAM_BUILD, paths.PROGRAM_CHANGE, paths.PELVIC_ASSESSMENT]}
              />
            )}
          </div>
        </StripeProvider>
      </GrowthBookProvider>
    </ErrorBoundary>
  )
}
