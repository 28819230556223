import { useLocation } from '@reach/router'
import _ from 'lodash'
import React from 'react'
import ButtonHistoryBack from 'components/ButtonHistoryBack'
import Container from 'components/Container'
import Copy from 'components/Copy'
import Head from 'components/Head'
import Heading from 'components/Heading'
import Loader from 'components/Loader'
import Row from 'components/Row'
import Text from 'components/Text'
import View from 'components/View'
import paths from 'constants/paths'
import LessonVideo from 'features/lesson/components/LessonVideo'
import useEventPageVisited from 'hooks/use-event-page-visited'
import * as movementQuery from 'libs/movement-query'
import styles from './Movement.module.scss'

type Props = {
  movementId: string
}

export default function Movement({ movementId }: Props) {
  useEventPageVisited('Movement')
  const location = useLocation()
  const { data: movementsData, isLoading: isMovementsLoading } = movementQuery.useUserMovements()
  const [movement, setMovement] = React.useState(location?.state?.movement.movement)

  React.useEffect(() => {
    if (isMovementsLoading || !_.isEmpty(movement)) return

    setMovement(movementsData.data.find((item) => item.movementId === movementId).movement)
  }, [isMovementsLoading, movement, movementId, movementsData.data])

  return (
    <View className="Movement">
      <Head bodyAttributes={{ class: 'body---grey' }} />
      <Container flush size="xxlarge">
        <Row size="small">
          <ButtonHistoryBack to={paths.MOVEMENTS} color="white" icon="arrow-left" size="small" />
        </Row>
        {isMovementsLoading && _.isEmpty(movement) ? (
          <Loader overlay={false} />
        ) : (
          <div className={styles['video-and-copy']}>
            <div>
              {!_.isEmpty(movement) && (
                <LessonVideo
                  buttonText="Start tutorial"
                  lesson={movement.educationLesson}
                  rounded
                />
              )}
            </div>
            <div>
              <Row size="xxsmall">
                <Text casing="upper" color="purple" element="h2" weight="bold">
                  {movement.educationLesson.type}
                </Text>
              </Row>
              <Heading level={2} levelStyle={4}>
                {movement.name}
              </Heading>
              <Copy>
                <Text element="h3">How to perform</Text>
                <Text element="p">{movement.howDescription}</Text>
                <Text element="h3">Why it’s important</Text>
                <Text element="p">{movement.whyDescription}</Text>
              </Copy>
            </div>
          </div>
        )}
      </Container>
    </View>
  )
}
