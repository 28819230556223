import classNames from 'classnames'
import React from 'react'
import BoldText from 'components/BoldText'
import Button from 'components/Button'
import Text, { type TextProps } from 'components/Text'
import Title from 'components/Title'
import paths from 'constants/paths'
import type { Component } from 'constants/types'
import * as subscriptions from 'libs/subscriptions'

type Props = Component

export default function SubscriptionUpgradeSection({ className }: Props) {
  const textProps: Partial<TextProps> = {
    casing: 'upper',
    color: 'purple',
    weight: 'bold',
  }

  return (
    <Title
      subtext="With Bold Premium, you’ll get unlimited access to personalized programs, classes, and benefits provided by Bold’s expert trainers."
      actions={
        <Button to={paths.SETTINGS_SUBSCRIPTION_CHANGE} size="xlarge">
          Change plan
        </Button>
      }
      size="small"
      className={classNames('SubscriptionUpgradeSection', className)}>
      Upgrade to
      <Text color="purple" wrap={false}>
        <BoldText />
        <Text {...textProps}>Premium</Text>
      </Text>{' '}
      by switching to a <Text {...textProps}>{subscriptions.PLANS.month.name2}</Text> or{' '}
      <Text {...textProps}>{subscriptions.PLANS.year.name2}</Text> membership plan today!
    </Title>
  )
}
