import classNames from 'classnames'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import Button from 'components/Button'
import Container from 'components/Container'
import Copy from 'components/Copy'
import Head from 'components/Head'
import Heading from 'components/Heading'
import type { HeadingProps } from 'components/Heading'
import Image from 'components/Image'
import JoinForm from 'components/JoinForm'
import Layout from 'components/Layout'
import Link from 'components/Link'
import Row from 'components/Row'
import Text from 'components/Text'
import View from 'components/View'
import useEventPageVisited from 'hooks/use-event-page-visited'
import styles from './Science.module.scss'
import { ReactComponent as PlaySvg } from './images/play.svg'
import { ReactComponent as ArthritisSvg } from './images/benefits/arthritis.svg'
import { ReactComponent as BoneSvg } from './images/benefits/bone.svg'
import { ReactComponent as ShowSvg } from './images/benefits/shoe.svg'
import garyMImage from './images/testimonials/gary-m@2x.jpg'
import kayRImage from './images/testimonials/kay-r@2x.jpg'
import susanLImage from './images/testimonials/susan-l@2x.jpg'

type ScienceTitleProps = {
  subtext: string
} & HeadingProps

function ScienceTitle({ subtext, ...props }: ScienceTitleProps) {
  return (
    <Row size="xmedium">
      <Text align="center" element="header">
        <Container flush size="large">
          <Heading {...props} />
          <Text element="p" flush>
            {subtext}
          </Text>
        </Container>
      </Text>
    </Row>
  )
}

export default function Science() {
  useEventPageVisited('Science')

  const whys = [
    {
      title: 'Tested and proven',
      copy: 'Bold’s team looks at the leading research and evidence around how certain exercises provide specific benefits to our mind and bodies as we age. We use this science to create smart programs that are tested and proven to impact your health.',
    },
    {
      title: 'Adaptable',
      copy: 'Bold trainers know how to modify exercises to accomodate health and mobility conditions, so every members can succeed at hitting their goals. By offering variations of exercises in formats, such as seated or standing, everyone can participate and improve their health.',
    },
    {
      title: 'Built by experts',
      copy: 'Each Bold class is led by a certified exercise instructor experienced in working with adults over 50. Bold trainers know which exercise movements are best for improving balance, strength, and mobility and how to modify exercises depending on age and health.',
    },
    {
      title: 'Get better results',
      copy: 'With Bold programs, you can expect to see and feel a difference within a few weeks of following your recommended program. Since every program is built on the best-in-class research, you can trust that you will get results, whether it is better balance, less pain, or more energy and mobility.  Read more about benefits below.',
    },
  ]

  const benefits = [
    {
      title: 'Fall prevention',
      copy: (
        <>
          <Text element="p">
            Over 1 in 3 older adults fall each year. However, most are preventable. Studies show
            that certain types of exercises — like Tai Chi — can help boost and condition balance.
          </Text>
          <Text element="p">
            Individuals who complete Bold’s unique Balance & Fall Prevention programs have improved
            their balance and strength and reduced falls by 46%.
          </Text>
          <Link to="https://formative.jmir.org/2021/12/e30558">
            <Text breakWord copy>
              formative.jmir.org/2021/12/e30558
            </Text>
          </Link>
        </>
      ),
      icon: BoneSvg,
      iconColor: 'purple',
    },
    {
      title: 'Arthritis',
      copy: (
        <>
          <Text element="p">
            Over 30 million Americans live with arthritis, which is also a leading cause of
            disability. Science-based exercises for arthritis can reduce pain and increase mobility,
            function and quality of life by up to 40%.
          </Text>
          <Text element="p">
            Bold’s Healthy Joints program used this research to create a special program for members
            with arthritis. Members report less pain and more energy in a matter of weeks.
          </Text>
          <Link to="https://cdc.gov/arthritis/basics/physical-activity-overview.html">
            <Text breakWord copy>
              cdc.gov/arthritis/basics/physical-activity-overview.html
            </Text>
          </Link>
        </>
      ),
      icon: ArthritisSvg,
      iconColor: 'blue',
    },
    {
      title: 'Sedentary lifestyle',
      copy: (
        <>
          <Text element="p">
            Most of adults in the U.S. lead sedentary lifestyles, and lack of physical activity can
            be a risk factor for obesity, type-2 diabetes, cancer, cardiovascular disease. Juggling
            life responsibilities can make it challenging to exercise and be active.
          </Text>
          <Text element="p">
            Bold has worked with leading behavioral scientists to develop programs that are easy to
            start and easy to stick-to. Bold Members are able to 2x their amount of activity within
            3 months.
          </Text>
        </>
      ),
      icon: ShowSvg,
      iconColor: 'cyan',
    },
  ]

  const testimonials = [
    {
      title: 'Susan L.',
      copy: 'Bouncing back with Bold from psoriatic arthririts.',
      image: susanLImage,
      to: 'https://www.youtube.com/watch?v=kjpK-Jf2FbI',
    },
    {
      title: 'Gary M.',
      copy: 'Starting exercise for the first time in your 70’s with multiple chronic conditions.',
      image: garyMImage,
      to: 'https://www.youtube.com/watch?v=A-Iyg0XfFUQ',
    },
    {
      title: 'Kay R.',
      copy: 'Improving balance and reducing fall risk with Bold.',
      image: kayRImage,
      to: 'https://www.youtube.com/watch?v=HRKdIJ5ZYXY',
    },
  ]

  const contentId = 'content'

  function handleLearnMoreClick() {
    const element = document.querySelector(`#${contentId}`)

    if (!element) return

    element.scrollIntoView({ behavior: 'smooth' })
  }

  const headingProps = {
    //levelStyle: 2,
    weight: 'normal',
  }

  return (
    <Layout className="Science">
      <Head
        title="Science-backed workouts to improve balance, strength and mobility"
        description="Bold’s fitness classes are rooted in the science of fall prevention to help you build for the future and live your strongest, healthiest life."
      />
      <Row>
        <header className={styles.header}>
          <StaticImage
            src="./images/header@2x.jpg"
            alt=""
            layout="fullWidth"
            loading="eager"
            className={styles['header--image']}
          />
          <div className={styles['header--content']}>
            <Container size="xxlarge">
              <Row>
                <Heading {...headingProps} flush level={1} levelStyle={2}>
                  Improve your health with Bold’s science-based programs
                </Heading>
              </Row>
              <Container align="left" flush size="xsmall">
                <Button
                  to={`#${contentId}`}
                  color="blue"
                  full
                  size="xlarge"
                  onClick={handleLearnMoreClick}>
                  Learn more
                </Button>
              </Container>
            </Container>
          </div>
        </header>
      </Row>
      <View id={contentId} className={styles.content}>
        <Text element="div" size="large">
          <Container flush size="xxlarge">
            <Row size="xxlarge">
              <div className={styles.summit}>
                <div>
                  <Heading {...headingProps}>Movement is medicine.</Heading>
                  <Text element="p">
                    Exercise can improve physical and mental health, but you don’t need to be an
                    scientist or billionaire in order to get science-based recommendations. At Bold,
                    our team takes the best research practices and put them into practice. All you
                    have to do is sign up and get moving.
                  </Text>
                </div>
                <Link to="https://www.youtube.com/watch?v=JnVYDEVlO4M">
                  <StaticImage
                    src="./images/be-bold-heathly-aging-summit-video@2x.jpg"
                    alt=""
                    layout="fullWidth"
                  />
                </Link>
              </div>
            </Row>
            <Row size="xxlarge">
              <Row>
                <Heading {...headingProps} flush>
                  Why choose science-based exercise programs?
                </Heading>
              </Row>
              <Copy columns={2} className={classNames(styles.whys, styles.copy)}>
                {whys.map((item) => (
                  <Row key={item.title} size="medium">
                    <Heading level={3} levelStyle={4}>
                      {item.title}
                    </Heading>
                    <Text element="p">{item.copy}</Text>
                  </Row>
                ))}
              </Copy>
            </Row>
            <Row size="xxlarge">
              <Row>
                <Heading {...headingProps} flush>
                  Bold specialty program benefits
                </Heading>
              </Row>
              <div className={classNames(styles.benefits, styles.copy)}>
                {benefits.map((item) => (
                  <div key={item.title}>
                    <Row size="xsmall">
                      <div
                        className={classNames(
                          styles['benefits--item--icon'],
                          styles[`benefits--item--icon---${item.iconColor}`]
                        )}>
                        <item.icon />
                      </div>
                    </Row>
                    <Heading level={3} levelStyle={4}>
                      {item.title}
                    </Heading>
                    {item.copy}
                  </div>
                ))}
              </div>
            </Row>
            <Row size="xxlarge">
              <ScienceTitle
                {...headingProps}
                subtext="Our members come from all walks of life, but have found common ground with improving their health through Bold.">
                Bold members get results
              </ScienceTitle>
              <Text align="center" className={styles.testimonials}>
                {testimonials.map((item) => (
                  <Text element="figure" key={item.title} className={styles['testimonials--item']}>
                    <Container
                      flush
                      size="xsmall"
                      className={styles['testimonials--item--container']}>
                      <Row size="xsmall">
                        <Link to={item.to} className={styles['testimonials--item--image-wrap']}>
                          <Image
                            src={item.image}
                            full
                            className={styles['testimonials--item--image']}
                          />
                          <PlaySvg
                            aria-hidden
                            className={styles['testimonials--item--play-button']}
                          />
                        </Link>
                      </Row>
                      <Text element="figcaption">
                        <Row size="xsmall">
                          <Text weight="bold">{item.title}</Text>
                        </Row>
                        <Text element="p" flush>
                          {item.copy}
                        </Text>
                      </Text>
                    </Container>
                  </Text>
                ))}
              </Text>
            </Row>
            <ScienceTitle
              {...headingProps}
              subtext="Get a free Bold member account today and receive a personalized program.">
              Ready to try Bold?
            </ScienceTitle>
            <JoinForm className={styles['sign-up-form']} />
          </Container>
        </Text>
      </View>
    </Layout>
  )
}
