import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import Copy from 'components/Copy'
import Heading from 'components/Heading'
import Row from 'components/Row'
import Text, { type TextProps } from 'components/Text'
import styles from './Title.module.scss'

type Props = {
  actions?: React.ReactNode
  children?: React.ReactNode
  flush?: boolean
  image?: React.ReactNode
  size?: 'large' | 'medium' | 'small'
  small?: string
  subtext?: string
} & TextProps

export default function Title({
  actions,
  align = 'center',
  children,
  className,
  flush,
  image,
  size = 'medium',
  small,
  subtext,
  ...props
}: Props) {
  let titleLevelStyle = 3

  if (size === 'large') titleLevelStyle = undefined
  if (size === 'small') titleLevelStyle = 4

  return (
    <Text
      {...props}
      align={align}
      element="header"
      className={classNames(
        'Title',
        styles.this,
        flush && styles['this---flush'],
        styles[`this---${size}`],
        className
      )}>
      {image && <Row size="small">{image}</Row>}
      {small && (
        <Row size="xsmall">
          <Text color="purple" element="h2" weight="bold" className={styles.small}>
            {small}
          </Text>
        </Row>
      )}
      {children && (
        <Row size="xsmall">
          <Heading
            flush
            level={2}
            levelStyle={titleLevelStyle}
            className={size === 'small' && styles.title}>
            {children}
          </Heading>
        </Row>
      )}
      {subtext && (
        <Row flush={!actions} size="small">
          <Copy
            element={_.isString(subtext) ? 'p' : undefined}
            size={size !== 'small' ? 'large' : undefined}>
            {subtext}
          </Copy>
        </Row>
      )}
      {actions && <div>{actions}</div>}
    </Text>
  )
}
