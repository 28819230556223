import React from 'react'
import Button from 'components/Button'
import Layout from 'components/Layout'
import Title from 'components/Title'
import View from 'components/View'
import paths from 'constants/paths'
import useEventPageVisited from 'hooks/use-event-page-visited'
import { ReactComponent as BrowserSvg } from './images/browser.svg'

export default function ErrorNotFound() {
  useEventPageVisited('404')

  return (
    <Layout className="ErrorNotFound">
      <View>
        <Title
          image={<BrowserSvg aria-hidden />}
          subtext="Sorry we could not find the page you were looking for."
          actions={<Button to={paths.HOME}>Go to home</Button>}
          flush>
          Page not found
        </Title>
      </View>
    </Layout>
  )
}
