import classNames from 'classnames'
import pluralize from 'pluralize'
import React from 'react'
import Heading from 'components/Heading'
import { type ModalProps } from 'components/Modal'
import ModalBox from 'components/ModalBox'
import Row from 'components/Row'
import Text from 'components/Text'
import { useGlobalContext } from 'contexts/GlobalContext'
import * as checkinApiRequest from 'libs/checkin-api-request'
import { useUserGoal } from 'libs/user-query'
import GoalForm from './components/GoalForm'
import RemindersForm from './components/RemindersForm'

type GoalAndRemindersModalBoxContentProps = {
  children: React.ReactNode
  subtext: string
  title: string
}

function GoalAndRemindersModalBoxContent({
  children,
  subtext,
  title,
}: GoalAndRemindersModalBoxContentProps) {
  return (
    <>
      <Heading levelStyle={5}>{title}</Heading>
      <Row size="xsmall">
        <Text element="p" flush>
          {subtext}
        </Text>
      </Row>
      {children}
    </>
  )
}

type Props = {
  // eslint-disable-next-line no-unused-vars
  onSuccess: (reminderDays?: any, successful?: boolean) => void
  status?: 'goal' | 'reminders'
} & ModalProps

export default function GoalAndRemindersModalBox({
  className,
  onSuccess,
  status: propsStatus = 'goal',
}: Props) {
  const globalContext = useGlobalContext()
  const { data: userGoalData } = useUserGoal()
  const [currentExerciseHabit, setCurrentExerciseHabit] = React.useState<string | undefined>()
  const [goalUpdated, setGoalUpdated] = React.useState<number | undefined>()
  const [status, setStatus] = React.useState<'goal' | 'reminders'>(propsStatus)

  React.useEffect(() => {
    globalContext.analytics?.trackEvent('Goal and reminders modal')
  }, [globalContext.analytics])

  React.useEffect(() => {
    async function getOnboardingResponses() {
      const onboardingResponse = await checkinApiRequest.getLatestUserCheckinResponses({
        checkinCode: 'default_onboarding_web',
      })
      if (onboardingResponse?.statusCode !== 200 || !onboardingResponse?.data) return
      setCurrentExerciseHabit(
        onboardingResponse?.data?.current_exercise_habit?.responseText.toLowerCase()
      )
    }
    getOnboardingResponses()
  })

  function handleGoalFormSubmit(goal: number) {
    setGoalUpdated(goal)
    // useEffect wasn't firing when value wasn't changed
    setStatus('reminders')
  }

  function handleBack() {
    if (propsStatus !== 'reminders') setStatus('goal')
    globalContext.analytics?.trackEvent('Goal and reminders: clicked back')
  }

  const goal = userGoalData?.data?.goalExerciseFrequency
  const setGoalSubtext = 'Set your weekly activity goal, and we’ll help you stay on track.'

  return (
    <ModalBox align="left" className={classNames('GoalAndRemindersModalBox', className)}>
      {status === 'goal' && (
        <GoalAndRemindersModalBoxContent
          title={goal ? 'Update your goal' : 'Set your goal'}
          subtext={
            goal
              ? `Your current workout goal is ${pluralize('day', goal, true)} per week. `
              : currentExerciseHabit
                ? `When you joined Bold, you told us you exercise ${currentExerciseHabit}. ${setGoalSubtext}`
                : setGoalSubtext
          }>
          <GoalForm onSubmit={handleGoalFormSubmit} />
        </GoalAndRemindersModalBoxContent>
      )}
      {status === 'reminders' && (
        <GoalAndRemindersModalBoxContent
          title={goal ? 'Update your reminders' : 'Set your reminders'}
          subtext={`Members who set reminders are twice as likely to reach their goal.
            Make a plan to reach your goal of ${pluralize(
              'class',
              goalUpdated ?? goal,
              true
            )} per week.`}>
          <RemindersForm goalUpdated={goalUpdated} onBack={handleBack} onSuccess={onSuccess} />
        </GoalAndRemindersModalBoxContent>
      )}
    </ModalBox>
  )
}
