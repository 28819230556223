import classNames from 'classnames'
import { Form, Formik } from 'formik'
import { navigate } from 'gatsby'
import React from 'react'
import * as Yup from 'yup'
import Button from 'components/Button'
import Container from 'components/Container'
import Divider from 'components/Divider'
import Field2 from 'components/Field2'
import Heading from 'components/Heading'
import Table from 'components/Table'
import View from 'components/View'
import paths from 'constants/paths'
import * as adminApiRequest from 'features/admin/libs/admin-api-request'
import * as validations from 'libs/validations'
import styles from './AdminEnterpriseMemberSearch.module.scss'

export default function AdminBoldlessEnterpriseMembers() {
  const [stateEnterprises, setStateEnterprises] = React.useState([])
  const [selectedEnterpriseId, setSelectedEnterpriseId] = React.useState('')
  const [enterpriseMembersData, setEnterpriseMembersData] = React.useState([])

  React.useEffect(() => {
    async function fetchEnterprises() {
      const getEnterprises = await adminApiRequest.getEnterpriseList()
      const fetchedEnterprises = getEnterprises.data
      setStateEnterprises(fetchedEnterprises)
      if (fetchedEnterprises && fetchedEnterprises.length > 0) {
        // set to grouper by default
        const grouperEnterprise = fetchedEnterprises.find((ent) => ent.internalId === 'grouper')
        setSelectedEnterpriseId(grouperEnterprise.id)
      }
    }
    fetchEnterprises()
  }, [])

  async function handleSubmit(values, formikActions) {
    const boldlessEnterpriseMembers = await adminApiRequest.adminFetchEnterpriseMembers(values)
    setEnterpriseMembersData(boldlessEnterpriseMembers.data)
    formikActions.setSubmitting(false)
  }

  async function handleRowClick(enterpriseMemberId: string) {
    navigate(`${paths.ADMIN_ENTERPRISE_MEMBER_PROFILE}?enterpriseMemberId=${enterpriseMemberId}`)
  }

  const enterpriseListDropdown = !stateEnterprises
    ? []
    : stateEnterprises.map((entrprse) => ({
        label: entrprse.name,
        value: entrprse.id,
      }))

  return (
    <View className="AdminEnterpriseMemberSearch">
      <Container align="left" size="small">
        <Heading level={2}>Enterprise Member Search</Heading>
        {selectedEnterpriseId && (
          <Formik
            initialValues={{
              enterpriseId: selectedEnterpriseId,
              name: null,
              dob: null,
            }}
            validationSchema={Yup.object({
              enterpriseId: validations.REQUIRED,
            })}
            onSubmit={handleSubmit}>
            {(formikProps) => (
              <Form>
                <Field2
                  label="Enterprise"
                  entity="partner"
                  name="enterpriseId"
                  type="select"
                  options={enterpriseListDropdown}
                />
                <Field2 label="Name" entity="name" name="name" />
                <Field2 label="Dob (YYYY-MM-DD)" entity="dob" name="dob" />
                <Button
                  id="adminFetchEnterpriseMembers"
                  disabled={formikProps.isSubmitting}
                  onClick={formikProps.submitForm}>
                  Get Enterprise Members
                </Button>
              </Form>
            )}
          </Formik>
        )}
        <Divider />
        {enterpriseMembersData && (
          <Table
            head={[
              'First name',
              'Last name',
              'Date of birth',
              'Email',
              'Phone',
              'Gender',
              'Preferred Language',
              'External ID',
              'Date of eligibility',
            ]}>
            {enterpriseMembersData.map((member) => (
              <tr
                key={member.id}
                className={classNames(styles['table-row'])}
                onClick={() => handleRowClick(member.id)}>
                <td className={classNames(styles['table-row-data'])}>{member.first_name}</td>
                <td className={classNames(styles['table-row-data'])}>{member.last_name}</td>
                <td className={classNames(styles['table-row-data'])}>{member.dob}</td>
                <td className={classNames(styles['table-row-data'])}>{member.email}</td>
                <td className={classNames(styles['table-row-data'])}>{member.phone_number}</td>
                <td className={classNames(styles['table-row-data'])}>{member.gender}</td>
                <td className={classNames(styles['table-row-data'])}>
                  {member.preferred_language}
                </td>
                <td className={classNames(styles['table-row-data'])}>{member.external_id}</td>
                <td className={classNames(styles['table-row-data'])}>
                  {member.eligibility_start_date}
                </td>
              </tr>
            ))}
          </Table>
        )}
      </Container>
    </View>
  )
}
