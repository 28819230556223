import React from 'react'
import Heading2 from 'components/Heading2'
import Loader from 'components/Loader'
import Row from 'components/Row'
import Text from 'components/Text'
import * as challenge from 'libs/challenge'
import type { Challenge } from 'libs/challenge'
import { useGetOrCreateActiveTodaysPlanChallenges } from 'libs/challenge-query'
import type { Lesson } from 'libs/lesson'
import styles from './DashboardToday.module.scss'
import DashboardTodayLessonLearn from './components/DashboardTodayLessonLearn'
import DashboardTodayLessonMove from './components/DashboardTodayLessonMove'
import { ReactComponent as CompleteBannerSvg } from './images/complete-banner.svg'

type Props = {
  // eslint-disable-next-line no-unused-vars
  onMoveLessonChange: (lesson: Lesson) => void
}

export default function DashboardToday({ onMoveLessonChange }: Props) {
  const { data: challenges, isLoading } = useGetOrCreateActiveTodaysPlanChallenges({
    revalidateOnFocus: false,
  })
  const challengesData = challenges?.data

  if (!challengesData) {
    return null
  }

  const learnChallenge = challengesData?.find(
    (item: Challenge) =>
      item.tag === challenge.CHALLENGE_TAG.LEARN ||
      item.challenge?.tag === challenge.CHALLENGE_TAG.LEARN
  )
  const moveChallenge = challengesData?.find(
    (item: Challenge) =>
      item.tag === challenge.CHALLENGE_TAG.MOVE ||
      item.challenge?.tag === challenge.CHALLENGE_TAG.MOVE
  )

  return (
    <section className="DashboardToday">
      <Heading2 level={2} levelStyle={4}>
        Today’s plan
      </Heading2>
      <Row size="small">
        <div className={styles.lessons}>
          {isLoading ? (
            <Loader overlay={false} />
          ) : (
            <>
              <DashboardTodayLessonMove
                challenge={moveChallenge}
                onMoveLessonChange={onMoveLessonChange}
              />
              <DashboardTodayLessonLearn challenge={learnChallenge} />
            </>
          )}
        </div>
      </Row>
      {learnChallenge?.complete && moveChallenge?.complete && (
        <Text align="center">
          <CompleteBannerSvg aria-label="Great job — 2/2 complete" />
        </Text>
      )}
    </section>
  )
}
