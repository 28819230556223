import classNames from 'classnames'
import React from 'react'
import AuthButton from 'components/AuthButton'
import Box from 'components/Box'
import Container from 'components/Container'
import Heading from 'components/Heading'
import Row from 'components/Row'
import Text from 'components/Text'
import LandingPill from 'components/landing/LandingPill'
import EnterpriseFitnessLanding, {
  type EnterpriseFitnessLandingProps,
} from 'features/enterprise/fitness/components/EnterpriseFitnessLanding'
import styles from './EnterpriseKelpLanding.module.scss'
import { ReactComponent as LogosSvg } from './images/logos.svg'

export type Props = EnterpriseFitnessLandingProps

export default function EnterpriseKelpLanding({ className, ...props }: Props) {
  const programs = [
    {
      title: 'Better balance',
      copy: '1-in-3 adults over 65 fall every year. Improve your confidence and lower your risk of falling with proven exercises.',
    },
    {
      title: 'Bladder control',
      copy: 'Support pelvic health and reduce urinary leakage with expert-guided resources and exercises.',
    },
    {
      title: 'Strength & well-being',
      copy: 'Personalized exercise routines to increase strength, independence, and mobility.',
    },
  ]

  return (
    <EnterpriseFitnessLanding
      {...props}
      heroProps={{
        title: 'Doctor-approved exercise to improve your health',
        subtext: (
          <>
            As a{' '}
            <Text color="yellow" weight="bold">
              Kaiser Permanente member with One Pass™
            </Text>
            , you have access to Bold to help better your balance, address bladder control, and
            increase strength and well-being — all at no cost.
          </>
        ),
      }}
      logos={LogosSvg}
      science={false}
      className={classNames('EnterpriseKelpLanding', className)}>
      <Text align="center" element="section" className={styles.programs}>
        <Container size="xxlarge">
          <LandingPill color="purple">Featured programs</LandingPill>
          <Row size="xmedium">
            <div className={styles['programs--grid']}>
              {programs.map((item) => (
                <Box color="white" key={item.title}>
                  <Heading color="purple" level={2} levelStyle={4}>
                    {item.title}
                  </Heading>
                  <Text element="p" flush>
                    {item.copy}
                  </Text>
                </Box>
              ))}
            </div>
          </Row>
          <AuthButton color="yellow" size="large" />
        </Container>
      </Text>
    </EnterpriseFitnessLanding>
  )
}
